<template>
  <div class="shiyong">
    <div class="shiyong-header"></div>
    <div class="exm-time">
      <!--  <div v-html="dateContent"></div> -->
      <div v-html="detail ? detail.topContent : ''"></div>
    </div>
    <div class="content">
      <!-- 分类列表 -->
      <div class="entrance-box">
        <el-collapse v-model="menuOneId">
          <el-collapse-item :name="item.id" class="item" v-for="(item, index) in menuList" :key="index">
            <template slot="title">
              <div class="item-title">{{ item.menuName }}</div>
            </template>
            <div class="content-text" :class="menuTwoId == chil.id ? 'on' : ''" v-for="(chil, chilIndex) in item.menuList"
              :key="chilIndex" @click="check(chil)">
              <div class="onIcon"></div>
              {{ chil.menuName }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 实用详情 -->
      <div class="detail">
        <div v-html="detail ? detail.content : ''"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Know } from "@/api/know";
let know = new Know();
import { getInfo } from "@/api/cookies";
import { selectPcList } from "@/api/home";
export default {
  components: {},

  data() {
    return {
      menuOneId: null,
      menuTwoId: null,
      detail: null,
      kind: null,
      menuList: [],
      flatArr: [],
    };
  },

  async created() {
    this.kind = this.$route.query.kind;
    this.menuOneId = this.$route.query.menuOneId
      ? this.$route.query.menuOneId.split(",").map(Number)
      : null;
    this.menuTwoId = this.$route.query.menuTwoId
      ? this.$route.query.menuTwoId
      : null;
    this.selectPcList();
  },
  methods: {
    selectPcList() {
      let data = {
        kind: this.kind,
      };
      selectPcList(data)
        .then((res) => {
          this.menuList = res.data;
        })
        .then(() => {
          this.defaultDisplay(this.menuList);
          this.detail = this.flatArr.find((item) => {
            return item.id == this.menuTwoId;
          });
        });
    },

    defaultDisplay(menuList) {
      this.detail = menuList.forEach((item) => {
        if (item.menuList && item.menuList.length > 0) {

          this.defaultDisplay(item.menuList);
        } else {
          this.flatArr.push(item);
        }
      });
    },

    check(chil) {
      if (chil) {
        this.detail = chil;
        this.menuTwoId = chil.id;
      }

      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.el-dropdown-menu {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

/* 下拉筛选 */
/deep/ .dropBox {
  margin-bottom: 16px;
  margin-top: 28px;
  margin-left: 28px;
  max-width: 600px;

  .title {
    /* font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400; */
    color: #ffffff;
    line-height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #ffffff;
    padding: 0px 14px;
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;

    .iconfont {
      font-size: 20px;
      color: #fff;
      margin-left: 8px;
      flex-shrink: 0;
    }
  }
}

// 大盒子
.shiyong {
  width: 100%;
  height: auto;
  position: relative;

  .shiyong-header {
    background: url("~@/assets/img/homeSeventh/shiyongBg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 220px;
    padding: 37px calc(50% - 810px) 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-logo {
      height: 68px;
    }

    .line {
      width: 1px;
      height: 50px;
      border: 1px solid #ccd7ff;
      margin: 0px 33px;
    }

    .line-title {
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }

  /* 时间排序 */
  .exm-time {
    width: 1619px;
    height: 193px;
    background: #ffffff;
    box-shadow: -1px 0px 6px 0px #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 0px calc(50% - 810px);
    padding: 24px;
    top: 141px;
    overflow: hidden;
  }

  /* 内容 */
  .content {
    width: 1619px;
    margin: 153px calc(50% - 810px) 0px;
    display: flex;

    /deep/ .entrance-box {
      width: 282px;
      background: #ffffff;
      box-shadow: -1px 0px 6px 0px #d8d8d8;
      border-radius: 8px;
      margin-right: 40px;
      /*   max-height: 80vh; */
      overflow: hidden;
      overflow-y: scroll;

      .el-collapse {
        width: 100%;
      }

      .item {
        .el-collapse-item__arrow {
          display: none;
        }

        .el-collapse-item__header {
          width: 100%;
          height: 72px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 72px;
          padding-left: 86px;
          cursor: pointer;
          background: none;
        }

        /* 二级分类 */
        .el-collapse-item__content {
          padding: 9px 0px !important;
        }

        .content-text {
          position: relative;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 25px;
          cursor: pointer;
          padding: 9px 86px;

          .onIcon {
            display: none;
          }
        }

        .on {
          color: #5372f0 !important;

          .onIcon {
            display: block;
            position: absolute;
            width: 4px;
            height: 4px;
            background: #4a6af0;
            border-radius: 50%;
            left: 64px;
            top: 19px;
          }
        }
      }

      .is-active {
        background: url("~@/assets/img/homeSeventh/shiyongTitleBg.png");
        background-size: 100% 100%;
        color: #ffffff !important;
      }
    }

    .detail {
      width: 1297px;
      // max-height: 80vh;
      overflow-y: scroll;
      background: #ffffff;
      box-shadow: -1px 0px 6px 0px #d8d8d8;
      border-radius: 8px;

      padding: 57px 63px;
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}

.item-title {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1220px) {
  .shiyong {
    background-color: red;

    .exm-time {
      width: 1100px !important;
      margin: auto;
    }

    .content {
      width: 1100px !important;

      .detail {
        width: 300px;
      }
    }
  }


}</style>
